@import "@tika/mandi-player/dist/index.css";

div::-webkit-scrollbar {
  display: none;
}
body {
  background-color: #1e2027;
  --plyr-color-main: #784ac4;
}

img {
  font-size: 12px;
  font-family: IranYekan, Poppins;
  color: rgba(#ebebeb, 0.1);
  text-align: center;
}
.vjs-time-control {
  //padding-left: 0px !important;
  //padding-right: 0px !important;
}