@import "./global";

.chat {
  &-outer-container-xs {
    position: fixed;
    z-index: 5;
    left: 0px;
    bottom: 80px;
    height: 50px;
    width: 50px;
  }
  &-inner-container-xs {
    height: 100%;
    width: 100%;
    @include row-center();
    background-color: #784ac4;
    border-radius: 0px 8px 8px 0px;
    box-shadow: 0px 3px 6px #0000004D;

  }
  &-xs {
      height: 30px;
  }
}
