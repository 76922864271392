@mixin column-center() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin row-center() {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@mixin iranyekan() {
  font-family: IranYekan, Poppins;
  direction: rtl;
}
