@import "./global";

.chat {
  &-outer-container-xl {
    position: fixed;
    z-index: 5;
    left: 0px;
    bottom: 86px;
    height: 60px;
    width: 60px;
  }
  &-inner-container-xl {
    height: 100%;
    width: 100%;
    @include row-center();
    background-color: #784ac4;
    border-radius: 0px 8px 8px 0px;
    box-shadow: 0px 3px 6px #0000004d;
    cursor: pointer;
  }
  &-xl {
    height: 36px;
  }
}
